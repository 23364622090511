<template>
    <div v-if="dataSource" class="p-relative">

        <Toolbar
            :isList="true"
            :hideCreateButton="hideCreate"
            :menu="dataSource.Data.Menu"
            v-on:toolbar-button-click="onToolbarClick"
        >
            <div class="top-btns-bar-right-section">

                <v-text-field
                    v-model="search"
                    append-icon="fas fa-search"
                    :label="$t('Что_ищем')"
                    hide-details
                    class="search-in-tooltip"
                    solo
                    flat
                    clearable
                ></v-text-field>

            </div>

        </Toolbar>

        <FilterPanel
            v-if="filterDataSource"
            :dataSource="filterDataSource"
            v-bind:status.sync="collection"
            v-bind:substatus.sync="status"
        >
        </FilterPanel>

        <component
            :is="collection"
            :status="status"
            :search="search"
            ref="currentComponent"
        />

    </div>
</template>

<script>
import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

import Toolbar from '@/components/Toolbar.vue';
import FilterPanel from '@/components/FilterPanel.vue';

import IncomingDSP from './Lists/IncomingDSP.vue';
import OutgoingDSP from './Lists/OutgoingDSP.vue';

export default {
    name: "DocumentsDSPProxy",
    components: {
        Toolbar,
        FilterPanel,
        IncomingDSP,
        OutgoingDSP
    },
    data() {
        return {
            cancellationTokenSorce: null,
            dataSource: null,
            filterDataSource: null
        }
    },
    computed: {
        ...mapGetters('auth', ['getUserInfo']),
        hideCreate() {
            return Array.from(this.getUserInfo?.permissions ?? [])
                        .some(el => ["CreateIncomingDSP", "CreateOutgoingDSP"].includes(el)) == false;
        },
        collection: {
            get: function() {
                return this.$store.getters['dsp/getCollection']
            },
            set: function(newValue) {
                this.$store.commit('dsp/SET_COLLECTION', newValue);
            }
        },
        status: {
            get: function() {
                return this.$store.getters['dsp/getStatus'];
            },
            set: function(newValue) {
                if (newValue) {
                    this.$store.commit('dsp/PUSH_STATUS', { collection: this.collection, value: newValue } );

                    if (typeof this.$refs.currentComponent?.resetPage === "function") { 
                        this.$refs.currentComponent.resetPage();
                    }
                }
            }
        },
        search: {
            get: function() {
                return this.$store.getters['dsp/getSearch']
            },
            set: _.debounce(function(v) {
                if (typeof this.$refs.currentComponent?.resetPage === "function") { 
                    this.$refs.currentComponent.resetPage();
                }
                this.$store.commit('dsp/PUSH_SEARCH', { collection: this.collection, value: v } );
            }, 500)
        },
    },
    methods: {
        ...mapActions(['setOverlayVisible']),
        onToolbarClick(event, btn) {
            this[btn.Action](btn);
        },
        async loadData() {
            this.setOverlayVisible({ visible: true });

            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('Обновление предыдущей категории прервано из-за выбора новой категории');

            this.cancellationTokenSorce = Axios.CancelToken.source();

            let response = await httpAPI({
                url: `api/actions/collection?type=Documents.DSP`,
                method: 'GET'
            });

            if (response) {
                await this.loadFilters();
                this.dataSource = response.data.payload;
            }

            this.setOverlayVisible({ visible: false });
        },
        async loadFilters() {
            let filterResponse = await httpAPI({
                url: `api/dsp/filter?category=${this.collection}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });

            if (filterResponse) {
                this.filterDataSource = filterResponse.data.payload;
            }
        },
        async Refresh() {
            await this.loadFilters();

            if (typeof this.$refs.currentComponent?.getData === "function") { 
                this.$refs.currentComponent.getData(true);
            }
        },
    },
    async created() {
        await this.loadData();
    },
    watch: {
        collection() {
            this.loadData();
        }
    },
}
</script>
<template>
    <v-row no-gutters>

        <v-col cols="12" xs="12" md="12">

            <v-data-table
                :loading="loading"
                :headers="headers"
                :items="entities"
                :item-class="itemRowBackground"
                :options.sync="options"
                :server-items-length="total"
                :items-per-page="10"
                :footer-props="{ itemsPerPageOptions:[5, 10, 20] }"
                :page.sync="options.page"
                @page-count="paginationPageCount = $event"
                class="elevation-1 customization-list-table pagination-list-table sticky-table"
                @dblclick:row="(event, data) => $eventBus.$emit('open-document', { id: data.item.id, type: 'Chancellery|Documents.Document' })"
            >
                <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>

                <template v-slot:[`item.regdate`]="{ item }">
                    <div>{{dateFormat(item.regdate, 'DD.MM.YYYY')}}</div>
                </template>

                <template v-slot:[`item.createdate`]="{ item }">
                    <div>{{dateFormat(item.createdate, 'DD.MM.YYYY')}}</div>
                </template>

                <template v-slot:[`item.correspondent`]="{ item }">
                    <div class="elipsis-text-wrap">
                        <v-enterprise-chip :name="item.correspondent" />
                    </div>
                </template>

                <template v-slot:[`item.theme`]="{ item }">
                    <div class="elipsis-text-wrap">
                        <div class="etw-text-container">
                            <div class="etw-text" v-tooltip.top-center="item.theme">
                                {{ item.theme }}
                            </div>
                        </div>
                    </div>
                </template>

                <template v-slot:[`item.actualoutgoingdate`]="{ item }">
                    <div>{{dateFormat(item.actualoutgoingdate, 'DD.MM.YYYY')}}</div>
                </template>

                <template v-slot:[`footer.prepend`]>
                    <v-pagination
                        v-model="options.page"
                        :length="paginationPageCount"
                        :total-visible="$listPaginationTotal"
                        color="teal"
                    ></v-pagination>
                </template>

            </v-data-table>

        </v-col>

    </v-row>
</template>

<script>
import i18n from '@/i18n';
import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";
import sys from "@/services/system";

export default {
    columnIndex:  {
        'regnumber': 2,
        'regdate': 3,
        'actualincomingdate': 7,
        'correspondent': 5,
        'theme': 6,
        'createdate': 4
    },
    outgoingDocStatuses: {
        "All": "0",
        "Deleted": "1000",
    },
    props: {
        status: {
            type: String,
            default: null
        },
        search: {
            type: String,
            default: ""
        }
    },
    computed: {
        options: {
            get: function() {
                return this.$store.getters['dsp/outgoing/GetTableOptions'];
            },
            set: function(newValue) {
                this.$store.commit('dsp/outgoing/SET_TABLE_OPTION', newValue);
            }
        },
        collection () {
            return this.$store.getters['dsp/getCollection'];
        },
        filter () {
            return {
                ...this.options,
                SearchStr: this.search,
                DocStatus: this.status
            }
        },
    },
    data () {
        return {
            loading: true,
            cancellationTokenSorce: null,
            headers: [
                { text: i18n.t("Рег._№"), value: 'regnumber', width: '150px' },
                { text: i18n.t("Рег._дата"), value: 'regdate', width: '110px' },
                { text: i18n.t("Фактическая_дата_отправки_сокр"), value: 'actualoutgoingdate', width: '130px' },
                { text: i18n.t("Корреспондент"), value: 'correspondent', width: '10%' },
                { text: i18n.t("Краткое_содержание"), value: 'theme'},
                { text: i18n.t("Дата_создания"), value: 'createdate', width: '140px' },
            ],
            total: 0,
            entities: [],
            prevTableFilterObject: null,
            paginationPageCount: 0,
        }
    },
    methods: {
        itemRowBackground (item) {
            return item.readed === true ? '' : 'font-weight-bold';
        },
        resetPage() {
            this.$store.commit('dsp/outgoing/SET_TABLE_OPTION_PAGE', 1);
        },
        async getData(isRefresh = false) {
            if (isRefresh === false && this.$_.isEqual(this.prevTableFilterObject, this.getTableFilterObject()))
                return;
                
            this.prevTableFilterObject = this.getTableFilterObject();
            this.total = 0;
            this.entities = [];
            this.loading = true;

            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('New request started');

            this.cancellationTokenSorce = Axios.CancelToken.source();
            
            let response = await httpAPI({
                url: `api/dsp/list?collection=${this.collection}&filter=${JSON.stringify(this.getTableFilterObject())}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });

            this.entities = response.data?.payload?.data ?? [];
            this.total = response.data?.payload?.total ?? 0;
            this.loading = false;
        },
        getTableFilterObject() {
            let { sortBy, sortDesc, page, itemsPerPage, SearchStr, DocStatus } = this.filter;
            let OrderDir = sortDesc.length <= 0 ? false : sortDesc[0];
            let sortColumn = sortBy.length <= 0 ? "regdate" : sortBy[0];
            let OrderBy = this.$options.columnIndex[sortColumn] ?? 3;

            return {
                SearchStr,
                DocStatus: this.$options.outgoingDocStatuses[DocStatus],
                OrderBy,
                OrderDir,
                Limit: itemsPerPage,
                Offset: (page - 1) * itemsPerPage
            };
        },
        dateFormat: sys.dateFormat,
    },
    watch: {
        filter: {
            handler() {
                this.getData()
            },
            deep: true,
        },
    },
}
</script>